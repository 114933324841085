/************************* PRIMARY SMALLS *************************/
.btn-primary_small {
    align-items: center;  
    background-color: var(--marine-5); 
    border: 1px solid var(--marine-5);  
    border-radius: 4px; 
    box-shadow: 0; 
    color: var(--white); 
    display: flex; 
    flex-direction: row;
    gap: var(--space-02);   
    height: 28px; 
    justify-content: center;
    opacity: 100%;   
    padding: 8px 12px; 
    transition: 80ms; 
    width: auto;   
    box-shadow: 0;
}

.btn-primary_small:disabled {
    opacity: 20%;   
}

.btn-primary_small:hover {
    background-color: var(--marine-6);  
    border: 1px solid var(--marine-6);
    box-shadow: var(--elevation-3); 
}

.btn-primary_small:active {
    border: 1px solid var(--white);
    box-shadow: var(--elevation-3);  
}

.btn-primary_small:focus {
    background-color: var(--marine-6);   
    border-color: var(--marine-6);            
}

/************************* PRIMARY MEDIUMS *************************/
.btn-primary_medium {
    align-items: center;  
    background-color: var(--marine-5); 
    border: 1px solid var(--marine-5);  
    border-radius: 4px; 
    box-shadow: 0; 
    color: var(--white); 
    display: flex; 
    flex-direction: row;
    gap: var(--space-02);   
    height: 40px; 
    justify-content: center;
    opacity: 100%;   
    padding: 12px 16px; 
    transition: 80ms; 
    width: auto;   
    box-shadow: 0; 
}

.btn-primary_medium:disabled {
    opacity: 20%; 
}

.btn-primary_medium:hover {
    background-color: var(--marine-6);  
    border: 1px solid var(--marine-6);
    box-shadow: var(--elevation-3); 
}

.btn-primary_medium:active {
    border: 1px solid var(--white);
    box-shadow: var(--elevation-3);
}

.btn-primary_medium:focus {
    background-color: var(--marine-6);   
    border-color: var(--marine-6);   
}

/************************* PRIMARY LARGE *************************/
.btn-primary_large {
    align-items: center;  
    background-color: var(--marine-5); 
    border: 1px solid var(--marine-5);  
    border-radius: 4px; 
    box-shadow: 0; 
    color: var(--white); 
    display: flex; 
    flex-direction: row;
    gap: var(--space-02);   
    height: 53px; 
    justify-content: center;
    opacity: 100%;   
    padding: 16px 24px; 
    transition: 80ms; 
    width: auto;   
    box-shadow: 0; 
}

.btn-primary_large:disabled {
    opacity: 20%; 
}

.btn-primary_large:hover {
    background-color: var(--marine-6);  
    border: 1px solid var(--marine-6);
    box-shadow: var(--elevation-3); 
}

.btn-primary_large:active {
    border: 1px solid var(--white);
    box-shadow: var(--elevation-3);
}

.btn-primary_large:focus {
    background-color: var(--marine-6);   
    border-color: var(--marine-6);   
}

