.myCheckbox {
    border-radius: 50%;
    background-color: #f4953b;
    width: 4.1em;
    height: 4.1em;
    padding: 2px; 
    border: 2px solid transparent;
    background-clip: content-box;
    transition: transform 0.5s ease, border 0.5s ease;
}

.myCheckbox:hover {
    border: 2px solid #f4953b;
    transform: scale(1.3);
}



.wrapper{
    margin-left:150px;
    margin-top:50px;
}