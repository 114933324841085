.dropdown {
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: var(--elevation-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    // min-height: 320px;
    opacity: 1;
}